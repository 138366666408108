<template>
  <v-card
    class="elevation-0"
    outlined
  >
    <HeaderCard>
      <b>{{ 'title.firstAccess'.translate() }}</b>
    </HeaderCard>

    <v-card-text style="padding-top: 0; padding-bottom: 0">
      <v-form
        ref="form"
        v-model="valid"
        autocomplete="off"
      >
        <IgnoreAutoFill />

        <v-layout
          wrap
          style="padding: 12px 0"
        >
          <v-flex xs12>
            <FieldEmail
              v-model="username"
              icon="mdi-shield-account"
              @on-enter="submitCreateUser"
              autofocus
            />
          </v-flex>

          <v-flex xs12>
            <FieldCore
              v-model="nucleoId"
              @on-enter="submitCreateUser"
            />
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>

    <Message
      type="error"
      icon="mdi-cloud-alert"

      :show="showError"
      :message="auth.error"
    />

    <v-divider />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          text
          small
          color="primary"
          :to="linkLogin"
        >
          {{ 'btn.back'.translate() }}
        </v-btn>
        <v-btn
          text
          small
          color="primary"
          :to="linkRecoveryPass"
        >
          {{ 'btn.recovery'.translate() }}
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          :disabled="!valid"
          @click="submitCreateUser"
          :loading="loading"
        >
          {{ 'btn.register'.translate() }}
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/components/Message'
import HeaderCard from '@/components/HeaderCard'
import FieldCore from '@/components/fields/FieldCore'
import FieldEmail from '@/components/fields/FieldEmail'
import IgnoreAutoFill from './IgnoreAutoFill'

import {
  ROUTE_AUTH_LOGIN,
  ROUTE_AUTH_RECOVERY,

  ACTION_USER_CREATE
} from '@/constants'

export default {
  components: {
    Message,
    HeaderCard,

    FieldCore,
    FieldEmail,
    IgnoreAutoFill
  },

  data () {
    return {
      valid: true,
      loading: false,
      showError: false,

      nucleoId: null,
      username: ''
    }
  },

  computed: {
    linkLogin: () => ({ name: ROUTE_AUTH_LOGIN }),
    linkRecoveryPass: () => ({ name: ROUTE_AUTH_RECOVERY }),

    ...mapState({
      auth: ({ auth }) => ({ error: auth.error, loading: auth.loading })
    })
  },

  methods: {
    submitCreateUser () {
      if (!this.valid) return
      if (this.loading) return
      this.loading = true

      this.$nextTick(() => (
        this.$store.dispatch(ACTION_USER_CREATE, {
          username: this.username,
          nucleoId: this.nucleoId
        })
      ))
    }
  },

  watch: {
    username () {
      this.showError = false
    },

    'auth.loading': function (loading) {
      if (this.loading !== loading) this.loading = loading
    },

    'auth.error': function (error) {
      this.showError = Boolean(error)
    }
  }
}
</script>
